import { render, staticRenderFns } from "./EditRole.vue?vue&type=template&id=2bdb1856&scoped=true&v-if=user.role%20%3D%3D%20'super_admin'"
import script from "./EditRole.vue?vue&type=script&lang=js"
export * from "./EditRole.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bdb1856",
  null
  
)

export default component.exports